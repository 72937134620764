.item {
  @apply p-3;
  @apply grid;
  @apply relative;
  @apply bg-white;
  @apply mb-4;
  @apply shadow-jobitem;
  @apply cursor-pointer;

  margin-right: -1.5rem;
  margin-left: -1.5rem;
  gap: 0.5rem 1.5rem;
  grid-template-columns: 5rem auto 3rem;
  grid-template-rows: auto auto;
  grid-template-areas:
    'logo title save'
    'logo salary save';

  &__logo {
    grid-area: logo;
    width: 5rem;
    height: 5rem;
  }

  &__title {
    grid-area: title;

    h3 {
      @apply text-md;
      @apply font-extrabold;
      @apply leading-8;
    }
  }

  &__salary {
    grid-area: salary;
  }

  &__save {
    grid-area: save;
  }

  &__details {
    grid-area: details;
    display: none;
    svg {
      @apply text-neutral-600;
    }
  }

  &__tags {
    display: none;
    grid-area: tags;
  }

  @screen md {
    @apply p-5;
    @apply rounded-lg;
    @apply mx-0;

    grid-template-columns: 6rem auto auto;
    grid-template-rows: auto auto;
    grid-template-areas:
      'logo title salary'
      'logo details save';
    gap: 0.5rem 2rem;

    &__logo {
      width: 6rem;
      height: 6rem;
    }

    &__title {
      h3 {
        @apply text-18;
        @apply mb-2;
      }
    }

    &__details {
      display: flex;
      @apply font-medium;
      @apply gap-4;
      @apply text-xs;
      @apply mt-4;
      @apply text-black;
    }

    &__save {
      display: flex;
      justify-content: flex-end;
      margin-top: -1rem;
      margin-right: -1rem;
    }

    &__salary {
      display: flex;
      justify-content: flex-end;
    }
  }

  @screen lg {
    grid-template-columns: 6rem auto auto 2rem;
    grid-template-rows: auto auto;
    grid-template-areas:
      'logo title salary save'
      'logo details tags save';

    &__title {
      h3 {
        @apply mb-0;
      }
    }

    &__tags {
      display: flex;
      justify-content: flex-end;
    }
  }
}
